import React, { createContext, useContext, useReducer } from 'react';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import inBrowser from '../utils/inBrowser';

import axios from 'axios';
const apiURL = process.env.API_URL;

const DEFAULT_STATE = {
    token: null,
    user: {},
    loggedIn: false,
};


const getUser = () =>
    inBrowser && Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};

const getToken = () =>
    inBrowser && Cookies.get('token') ? JSON.parse(Cookies.get('token')).value : null;

const getTokenExpiration = () =>
    inBrowser && Cookies.get('token')
        ? JSON.parse(Cookies.get('token')).expiry
        : null;

const setWithExpiry = (key, value, expires_at) => {
    const item = {
        value,
        expiry: expires_at,
    };
    Cookies.set(key, JSON.stringify(item), {expires: new Date(expires_at)});
};

const checkTokenExpiration = () => {
    const expiry = getTokenExpiration();
    if (new Date(expiry) < Date.now()) {
        return true;
    }
    return false;
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            const { user = {} } = action.payload;
            Cookies.set('user', JSON.stringify(user));
            return { ...state, user, loggedIn: true };
        case 'SET_TOKEN':
            const { token = null, expires_at = null } = action.payload;
            setWithExpiry('token', token, expires_at);
            return { ...state, token, user, loggedIn: true };
        case 'LOGOUT':
            Cookies.remove('token');
            Cookies.remove('user');
            return { ...state, token: null, user: {}, loggedIn: false };
        default:
            return DEFAULT_STATE;
    }
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => (
    <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>{children}</AuthContext.Provider>
);

export const wrapRootElement = ({ element }) => <AuthProvider>{element}</AuthProvider>;

const useAuth = () => {
    const [state, dispatcher] = useContext(AuthContext);
    const isAuthenticated = !!(getUser() && getToken());
    const user = getUser();
    const token = getToken();
    const hasTokenExpired = checkTokenExpiration();

    if (token != null && hasTokenExpired) {
        Cookies.remove('token');
        Cookies.remove('user');
        navigate('/');
    }

    const login = async credentials =>
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/login`, credentials);
                dispatcher({ type: 'SET_USER', payload });
                dispatcher({ type: 'SET_TOKEN', payload });
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const register = async credentials =>
        console.log(credentials) ||
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/register`, {...credentials, headers: {'Content-Type': 'application/json;charset=UTF-8'}}
                );
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const updateUser = async credentials =>
        console.log(credentials) ||
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/details`, {...credentials}, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8'} });
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const updatePassword = async credentials =>
        console.log(credentials) ||
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/change-password`, {...credentials}, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8'} });
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const forgotPassword = async email =>
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/forgot`, email);
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const resetPassword = async credentials =>
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.post(`${apiURL}/api/auth/reset`, credentials);
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });

    const fetchUser = async () => {
        new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.get(`${apiURL}/api/auth/user`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                dispatcher({ type: 'SET_USER', payload: { user: payload } });
                resolve(payload);
            } catch (e) {
                console.log(e);
                reject(e);
            }
        });
    };

    const fetchReports = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: payload } = await axios.get(`${apiURL}/api/user/reports`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                resolve(payload);
                return payload;
            } catch (e) {
                console.log(e);
                reject(e);
            }
        }).then(response => {
            return response;
        });
    };

        const downloadReport = async report => {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data: payload } = await axios.post(
                        `${apiURL}/api/user/reports/download`,
                        { report_id: report.id },
                        {
                            responseType: 'blob',
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    ).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${report.name}.zip`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                    resolve(payload);
                    return payload;
                } catch (e) {
                    reject(e);
                }
            }).then(response => {
                return response;
            });
        };

    const logout = () => {
        dispatcher({ type: 'LOGOUT' });
        document.location.reload();
    };

    return {
        state,
        register,
        updateUser,
        login,
        logout,
        updatePassword,
        forgotPassword,
        resetPassword,
        fetchUser,
        fetchReports,
        downloadReport,
        user,
        token,
        isAuthenticated,
    };
};

export default useAuth;
